import VIDEO_PATH from "@/assets/original_video.mp4";
import IMAGE_0_PATH from "@/assets/image_0.jpg";
import IMAGE_1_PATH from "@/assets/image_1.jpg";
import IMAGE_0_SQUARE_PATH from "@/assets/image_0_square.jpg";
import IMAGE_1_SQUARE_PATH from "@/assets/image_1_square.jpg";
import GoogleMapsWidget from "@/components/GoogleMapsWidget";
import { Link } from "react-router-dom";
import MessageForm from "@/components/MessageForm";

export default function Home() {
  const days = ["Mon to Fri", "Sat", "Sun", "Statutory Holidays"];
  const hours = ["9AM - 12PM ; 1 - 5PM", "10AM - 3PM", "CLOSED", "CLOSED"];

  return (
    <>
      <section className="flex overflow-hidden relative flex-col justify-center w-full font-base text-center text-black min-h-[681px] max-md:max-w-full">
        <div className="flex overflow-hidden relative flex-col justify-center items-center p-20 w-full min-h-[681px] max-md:px-5 max-md:max-w-full">
          <div className="opacity-80">
            <video autoPlay muted loop playsInline className="object-cover absolute inset-0 size-full">
              <source src={VIDEO_PATH} type="video/mp4" />
            </video>
          </div>

          <div className="relative text-center text-neutral-900">
              <div className="text-6xl mb-40 uppercase leading-[50px] max-md:max-w-full max-md:text-4xl">
                Family Health Medical Centre
              </div>
          </div>
        </div>
      </section>

      {/* text-image */}
      {/* Hidden on mobile */}
      <section className="hidden lg:flex justify-center items-center pb-14 mt-24 w-full bg-white max-md:mt-10 max-md:max-w-full">
        <div className="flex justify-center items-center pb-14 w-full max-md:max-w-full">

          <div className="flex flex-col items-center w-1/2 2xl:pl-72">
            <div className="flex flex-col w-[61%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch pb-14 my-auto text-base font-base leading-6 text-stone-500 max-md:mt-10 max-md:max-w-full">

                <h2 className="text-2xl lg:text-3xl leading-7 uppercase text-zinc-800 w-[455px] max-md:max-w-full">
                  family is medicine
                </h2>

                <p className="mt-8 max-md:max-w-full">
                  At Family Health Medical Centre, dedicated to offering a personalized approach to medicine that puts your and your family’s wellbeing front and center.
                </p>
                <a href="/about" className="justify-center self-start px-5 py-2.5 mt-12 text-xl text-white rounded bg-teal-500 bg-opacity-90">
                  About Us
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-1/2 bg-neutral-200">
            <div className="flex flex-col ml-5 w-[39%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center self-stretch text-xs text-white whitespace-nowrap max-md:mt-10">
                <div className="flex flex-col pl-8 py-8 2xl:pl-16 xl:pl-16">
                  <div className="flex flex-col justify-center shadow-sm bg-white bg-opacity-0">
                    <div className="overflow-hidden relative flex-col justify-center items-center px-32 xl:px-16 pt-32 pb-32 w-full aspect-[1.41] max-md:px-5 max-md:pb-10">
                      <img loading="lazy" src={IMAGE_0_PATH} alt="Edmonton family health medical centre - family is medicine" className="object-cover absolute inset-0 size-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* text-image */}
      {/* Hidden on desktop */}
      <section className="hidden lg:hidden md:flex z-10 justify-center items-center px-16 pb-14 mt-24 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="justify-center py-7 max-w-full w-[1080px] max-md:pl-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">

            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col pr-5 max-md:mt-10 max-md:max-w-full">
                <h2 className="font-['Open_Sans'] text-2xl lg:text-3xl leading-7 uppercase text-zinc-800 w-[455px] max-md:max-w-full">
                  family is medicine
                </h2>
                <p className="mt-8 font-base leading-7 text-stone-500 max-md:max-w-full">
                  At Family Health Medical Centre, dedicated to offering a personalized approach to medicine that puts your and your family’s wellbeing front and center.
                </p>
                <Link to="/about" className="justify-center self-start px-5 py-2.5 mt-12 text-base lg:text-xl font-base text-white rounded bg-teal-500 bg-opacity-90 max-md:mt-10">
                  About Us
                </Link>
              </div>
            </div>
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <img loading="lazy" src={IMAGE_0_SQUARE_PATH} alt="Edmonton family health medical centre - personalized holistic medicine" className="" />
            </div>
          </div>
        </div>
      </section>

      {/* text-image */}
      {/* Hidden on desktop */}
      <section className="lg:hidden md:hidden flex z-10 justify-center items-center px-16 pb-14 mt-24 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="justify-center py-7 max-w-full w-[1080px] max-md:pl-5">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">

              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <img loading="lazy" src={IMAGE_0_SQUARE_PATH} alt="Edmonton family health medical centre - personalized holistic medicine" className="" />
              </div>

              <div className="flex flex-col pr-5 max-md:mt-10 max-md:max-w-full">
                <h2 className="font-sans text-2xl lg:text-3xl leading-7 uppercase text-zinc-800 w-[455px] max-md:max-w-full">
                  family is medicine
                </h2>
                <p className="mt-8 font-base leading-7 text-stone-500 max-md:max-w-full">
                  At Family Health Medical Centre, dedicated to offering a personalized approach to medicine that puts your and your family’s wellbeing front and center.
                </p>
                <Link to="/about" className="justify-center self-start px-5 py-2.5 mt-12 text-base lg:text-xl font-base text-white rounded bg-teal-500 bg-opacity-90 max-md:mt-10">
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* image-text */}
      <section className="flex z-10 justify-center items-center px-16 pb-14 mt-0 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="justify-center py-7 max-w-full w-[1080px] max-md:pl-5">

          <div className="hidden lg:flex gap-5 max-md:flex-col max-md:gap-0">

            {/* Hidden on mobile */}
            <div className="flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <img loading="lazy" src={IMAGE_1_PATH} alt="Edmonton family health medical centre - personalized holistic medicine" className="grow w-full aspect-[1.49] max-w-[459px] max-md:mt-10 max-md:max-w-full" />
            </div>

            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col pr-5 max-md:mt-10 max-md:max-w-full">
                <h2 className="lg:text-3xl text-2xl font-base leading-7 uppercase text-zinc-800  max-md:max-w-full">
                  personalized holistic medicine
                </h2>

                {/* Hidden on mobile */}
                <div className="">
                  <p className="mt-8 font-base leading-7 text-stone-500 max-md:max-w-full">
                    At our clinic, we provide comprehensive healthcare <br /> services tailored to meet the needs of every family <br /> member, from newborns to seniors.
                  </p>
                </div>

                <Link to="/about" className="justify-center self-start px-5 py-2.5 mt-12 text-base lg:text-xl font-base text-white rounded bg-teal-500 bg-opacity-90 max-md:mt-10">
                  What Makes Us Different
                </Link>
              </div>
            </div>
          </div>


          <div className="lg:hidden flex gap-5 max-md:flex-col max-md:gap-0">

            {/* Hidden on desktop */}
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <img loading="lazy" src={IMAGE_1_SQUARE_PATH} alt="Edmonton family health medical centre - personalized holistic medicine" className="" />
            </div>

            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col pr-5 max-md:mt-10 max-md:max-w-full">
                <h2 className="lg:text-3xl text-2xl font-base leading-7 uppercase text-zinc-800  max-md:max-w-full">
                  personalized holistic medicine
                </h2>

                {/* Hidden on desktop */}
                <div className="">
                  <p className="mt-8 font-base leading-7 text-stone-500 max-md:max-w-full">
                    At our clinic, we provide comprehensive healthcare services tailored to meet the needs of every family member, from newborns to seniors.
                  </p>
                </div>

                <Link to="/about" className="justify-center self-start px-5 py-2.5 mt-12 text-base lg:text-xl font-base text-white rounded bg-teal-500 bg-opacity-90 max-md:mt-10">
                  What Makes Us Different
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex justify-center items-center px-16 pb-14 w-full bg-neutral-100 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center max-w-full w-[1112px]">
          <div className="relative text-center">
            <div className="mt-14 text-5xl uppercase leading-[50px]  max-md:mt-10 max-md:max-w-full max-md:text-4xl">
              VISIT US
            </div>
          </div>

          <div className="flex flex-col justify-center self-stretch p-px mt-8 text-base border border-solid border-zinc-100 max-md:max-w-full">
            <address className="not-italic flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
              <div className="flex flex-col max-w-full w-[544px]">
                <p className="justify-center px-6 pt-2 pb-1.5 leading-7 border-t border-solid border-zinc-100 text-lg max-md:px-5 max-md:max-w-full text-center">
                  <span>Unit 301, 321 Parsons Road SW</span>
                  <br/>
                  <span>T6X 3B9, Edmonton, AB</span>
                </p>
              </div>
            </address>
          </div>

          <div className="flex flex-col justify-center mt-10 max-w-full">
            <GoogleMapsWidget 
              apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string}
              mapId="map"
              lat={53.43265}
              lng={-113.47761}
              zoom={12}
              position_lat={53.43265}
              position_lng={-113.47761}
            />
          </div>

          <h2 className="mt-9 mb-4 text-2xl leading-6 text-center uppercase text-zinc-800  max-md:max-w-full">Our Hours</h2>

          <div className="flex flex-col">
            {days.map((day, index) => (
              <div key={day} className="flex justify-between border-t border-solid border-zinc-100">
                <div className="pt-2 px-6 pb-1.5 border-t border-solid border-zinc-100">{day}</div>
                <div className="pt-2 px-6 pb-1.5 border-t border-solid border-zinc-100">{hours[index]}</div>
              </div>
            ))}
          </div>

          <div className="flex flex-col justify-center self-stretch mt-8 text-base border border-solid border-zinc-100 max-md:max-w-full">
            <address className="not-italic flex justify-center items-center px-16 max-md:px-5 max-md:max-w-full">
              <div className="flex flex-col max-w-full w-[544px]">
                <div className="flex flex-col self-center px-6 pt-2 pb-1.5 border-t border-solid border-zinc-100 leading-[160%] max-md:px-5">
                  <span className="">Tel: (780) 757-0088</span>
                  <span className="mt-4">Fax: (780) 757 6688</span>
                </div>
              </div>
            </address>
          </div>
        </div>
      </section>
      <section className="flex justify-center pb-14 w-full bg-neutral-100">
        <MessageForm />
      </section>
    </>
  );
}
